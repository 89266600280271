import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./quasar";
import i18n from "./i18n";
import "@/scss/custom.scss";
import VueGtag from "vue-gtag";
Vue.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_ANALYTICS_ID },
    pageTrackerTemplate(to) {
      let title = to.name;
      if (title == "Home") {
        title = "Home - Maipú Sustentable";
      }
      if (title == "Telefonos") {
        title = "Oficinas y Teléfonos - Maipú Sustentable";
      }
      if (title == "Tramites") {
        title = "Trámites y pagos online - Maipú Sustentable";
      }
      if (title == "Galerias") {
        title = "Galerías - Maipú Sustentable";
      }
      if (title == "DetalleGaleria") {
        title = "Galeria";
      }
      if (title == "Noticias") {
        title = "Noticias - Maipú Sustentable";
      }
      if (title == "Publicacion") {
        title = "Maipú Sustentable";
      }
      if (title == "PublicacionPreview") {
        title = "Noticia Preview";
      }
      if (title == "Categoria") {
        title = "Noticias";
      }
      if (title == "Comuna") {
        title = "Comuna";
      }
      if (title == "Municipalidad") {
        title = "Maipú Sustentable";
      }
      if (title == "Destacado") {
        title = "Destacado";
      }
      if (title == "Pagina") {
        title = "Maipú Sustentable";
      }
      if (title == "PaginaPreview") {
        title = "Preview Página";
      }
      if (title == "Contacto") {
        title = "Formulario de Consultas - Maipú Sustentable";
      }
      if (title == "Concejo") {
        title = "Concejo Municipal - Maipú Sustentable";
      }
      if (title == "Cam") {
        title = "Centros de Atención Municipal - Maipú Sustentable";
      }
      if (title == "Organizaciones") {
        title = "Ley 21.146 - Maipú Sustentable";
      }
      if (title == "OfertasOmil") {
        title = "Ofertas Laborales OMIL - Maipú Sustentable";
      }
      if (to.params.nombre) {
        if (title == "Maipú Sustentable") {
          title = to.params.nombre.replaceAll("-", " ") + " - " + title;
        } else {
          title = title + " - " + to.params.nombre.replaceAll("-", " ");
        }
      }
      return {
        page_title: title,
        page_path: to.path
      };
    }
  },
  router
);

//Truncate text
var filter = function (text, length, clamp) {
  clamp = clamp || "...";
  var node = document.createElement("div");
  node.innerHTML = text;
  var content = node.textContent;
  if (content) {
    return content.length > length ? content.slice(0, length) + clamp : content;
  } else {
    return text;
  }
};
Vue.filter("truncate", filter);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
